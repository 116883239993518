import react, { useEffect, useState } from "react";
import { firestore } from "../../firebase/config";
import GetReferenceDetails from "../GetReferenceDetails";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import { getDateInFormat } from "../utilities/Utils";
const UpdateOnRecord = () => {
  const [message, setMessage] = useState("");
  const [donations, setDonations] = useState([]);

  const runProcess = (e) => {
    e.preventDefault();
    console.log(`Starting job`);

    firestore
      .collectionGroup("donations")
      .get()
      .then((res) => {
        if (!res.empty) {
          res.forEach((data) => {
            let donationId = data.id;
            let donorId = data.ref.parent.parent.id;
            firestore
              .collection("donors")
              .doc(donorId)
              .collection("donations")
              .doc(donationId)
              .update({
                upiId: "",
                receivedThroughWebsite: false,
                csrDonation: false,
              });
            setMessage(
              (prevMessage) => prevMessage + "\n" + donorId + ": " + donationId
            );
          });
        }
      });
  }; //End of runProcess

  const getUPIDonations = (e) => {
    e.preventDefault();
    let donationData = [];

    firestore
      .collectionGroup("donations")
      .where("mode", "==", "UPI")
      .orderBy("date", "desc")
      .get({ source: "server" })
      .then((res) => {
        if (!res.empty) {
          res.forEach((donation) => {
            let donorId = donation.ref.parent.parent.id;

            donationData.push({
              donorId: donorId,
              donationId: donation.id,
              mode: donation.data().mode,
              upiId: donation.data().upiId,
              amount: donation.data().amount,
              date:
                donation.data().date !== "" &&
                donation.data().date !== undefined
                  ? getDateInFormat(donation.data().date.toDate().toString())
                  : "",
              donorName: (
                <GetReferenceDetails refId={donorId} needLink={true} />
              ),
            });
          });
        }

        setDonations(donationData);
      });
  }; //end of getUPIDonations
  return (
    <>
      <div className="card p-1 mb-1">
        <div className="justify-content-center d-flex">
          <h2>Update On Record - One Time Job</h2>
        </div>
        <div className="card p-1 mb-1">
          <form className="form-inline d-flex p-3 bg-secondary text-white text-center justify-content-center">
            <button
              className="btn btn-primary m-1"
              type="submit"
              onClick={runProcess}
            >
              Add fields to all donations
            </button>
            <br />
            <button
              className="btn btn-primary m-1"
              type="button"
              onClick={getUPIDonations}
            >
              Get UPI Donations
            </button>
          </form>
        </div>
        <div className="card p-1 mb-1">
          {message && (
            <>
              <ul>{message}</ul>
            </>
          )}
        </div>
        <div className="card p-1 mb-1">
          {donations.length > 0 && <UpdateUPI donations={donations} />}
        </div>
      </div>
    </>
  );
};

export default UpdateOnRecord;

export function UpdateUPI(props) {
  const handleButtonClick = (row) => {
    // console.log(row.upiId);
    console.log("Buttn clicked");
  };
  const addButton = (data, row) => {
    return (
      <>
        <button
          className="btn btn-primary"
          onClick={(e) => {
            let donorId = row.donorId;
            let donationId = row.donationId;
            let upiId = row.upiId;
            console.log(row.upiId);
            if (upiId !== "") {
              firestore
                .collection("donors")
                .doc(donorId)
                .collection("donations")
                .doc(donationId)
                .update({ upiId })
                .then((res) => alert("UPI Id added"));
            } else {
              alert("Enter UPI Id");
            }
          }}
        >
          Update
        </button>
      </>
    );
  };
  const columns = [
    {
      dataField: "donorId",
      text: "Donor Id",
      editable: false,
      hidden: true,
    },
    {
      dataField: "donorName",
      text: "Donor Name",
      editable: false,
    },
    {
      dataField: "donationId",
      text: "Donation Id",
      editable: false,
    },
    {
      dataField: "mode",
      text: "Mode",
      editable: false,
    },
    {
      dataField: "date",
      text: "Donation Date",
      editable: false,
    },
    {
      dataField: "amount",
      text: "Amount",
      editable: false,
    },
    {
      dataField: "upiId",
      text: "UPI Id",
    },
    {
      dataField: "action",
      text: "Action",
      isDummyField: true,
      formatter: addButton,
      editable: false,
    },
  ];
  return (
    <>
      <BootstrapTable
        data={props.donations}
        keyField="donationId"
        columns={columns}
        stipped
        hover
        condensed
        wrapperClasses="table-responsive"
        cellEdit={cellEditFactory({ mode: "dbclick" })}
      />
    </>
  );
}

// class UpdateOnRecord extends react.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       message: "",
//       donations: [],
//     };
//     this.runProcess = this.runProcess.bind(this);
//     this.getUPIDonations = this.getUPIDonations.bind(this);
//   }
//   getUPIDonations(e) {
//     e.preventDefault();
//     let donationData = [];
//     console.log("getUPIDonations");
//     firestore
//       .collectionGroup("donations")
//       .where("mode", "==", "UPI")
//       .get({ source: "server" })
//       .then((res) => {
//         if (!res.empty) {
//           res.forEach((donation) => {
//             console.log(donation.data().upiId);
//             let donorId = donation.ref.parent.parent.id;

//             donationData.push({
//               donorId: donorId,
//               donationId: donation.id,
//               mode: donation.data().mode,
//               upiId: donation.data().upiId,
//               donorName: (
//                 <GetReferenceDetails refId={donorId} needLink={false} />
//               ),
//               amount: donation.data().amount,
//             });
//           });
//         }

//         this.setState({ donations: donationData });
//       });
//   } //end of getUPIDonations
//   runProcess(e) {
//     e.preventDefault();
//     console.log(`Starting job`);

//     firestore
//       .collectionGroup("donations")
//       .get()
//       .then((res) => {
//         if (!res.empty) {
//           res.forEach((data) => {
//             console.log(data.ref.parent.parent.id);

//             let donationId = data.id;
//             let donorId = data.ref.parent.parent.id;
//             firestore
//               .collection("donors")
//               .doc(donorId)
//               .collection("donations")
//               .doc(donationId)
//               .update({
//                 upiId: "",
//               });
//             // this.state.message = +data.data().fullName + "/n";

//             this.setState((state) => {
//               return {
//                 message: `${state.message} \n ${data.id}`,
//               };
//             });
//           });
//         }
//       });
//   }

//   render() {
//     return (
//       <>
//         <div className="card p-1 mb-1">
//           <div className="justify-content-center d-flex">
//             <h2>Update On Record - One Time Job</h2>
//           </div>
//         </div>
//         <div className="card p-1 mb-1">
//           <form className="form-inline d-flex p-3 bg-secondary text-white text-center justify-content-center">
//             <button
//               className="btn btn-primary m-1"
//               type="submit"
//               onClick={this.runProcess}
//             >
//               Add UPI Id field to all donations
//             </button>
//             <br />
//             <button
//               className="btn btn-primary m-1"
//               type="button"
//               onClick={this.getUPIDonations}
//             >
//               Get UPI Donations
//             </button>
//           </form>
//         </div>
//         <div className="card p-1 mb-1">
//           {this.state.message && (
//             <>
//               <ul>{this.state.message}</ul>
//             </>
//           )}
//         </div>
//         <div className="card p-1 mb-1">
//           {this.state.donations.length > 0 && (
//             <>
//               <UpdateUPI donations={this.state.donations} />
//             </>
//           )}
//         </div>
//       </>
//     );
//   }
// }
// export default UpdateOnRecord;

// class UpdateUPI extends react.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       donations: props.donations,
//     };
//   }

//   handleUPIIdChance(e, donationId) {
//     let currentDonationIndex = this.props.donations.findIndex(
//       (d) => d.donationId === donationId
//     );
//     this.props.donations[currentDonationIndex].upiId = e.target.value;
//   }

//   async updateUPIId(e, donationId) {
//     e.preventDefault();
//     this.setState({ isUpdating: true });
//     let currentDonationIndex = this.props.donations.findIndex(
//       (d) => d.donationId === donationId
//     );

//     let donorId = this.props.donations[currentDonationIndex].donorId;
//     let upiId = this.props.donations[currentDonationIndex].upiId;
//     await firestore
//       .collection("donors")
//       .doc(donorId)
//       .collection("donations")
//       .doc(donationId)
//       .update({ upiId: upiId })
//       .then((res) => {
//         alert("UPI Id updated");
//       });
//     this.setState({ isUpdating: false });
//   }

//   render() {
//     return (
//       <>
//         <h3>{this.state.donations.length}</h3>
//         <div className="table-responsive">
//           <table className="table table-bordered">
//             <thead>
//               <tr>
//                 <th>Donor Name</th>
//                 <th>Donation Id</th>
//                 <th>Amount</th>
//                 <th>Mode</th>
//                 <th>UPI Id</th>
//               </tr>
//             </thead>
//             <tbody>
//               <DonationData
//                 updateUPIId={this.updateUPIId}
//                 handleUPIIdChance={this.handleUPIIdChance}
//                 donations={this.state.donations}
//               />
//               {/* {this.state.donations.map((donation) => {
//                 return (
//                   <tr>
//                     <td>{donation.donorName}</td>
//                     <td>{donation.donationId}</td>
//                     <td>{donation.amount}</td>
//                     <td>{donation.mode}</td>
//                     <td>
//                       <input type="text"></input>
//                     </td>
//                   </tr>
//                 );
//               })} */}
//             </tbody>
//           </table>
//         </div>
//       </>
//     );
//   }
// }

// class DonationData extends react.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isUpdating: false,
//     };
//   }

//   render() {
//     return (
//       <>
//         {this.props.donations.map((donation) => {
//           return (
//             <tr>
//               <td>{donation.donorName}</td>
//               <td>{donation.donationId}</td>
//               <td>{donation.amount}</td>
//               <td>{donation.mode}</td>
//               <td>
//                 <form
//                   onSubmit={(e) =>
//                     this.props.updateUPIId.call(this, e, donation.donationId)
//                   }
//                 >
//                   <input
//                     type="text"
//                     className="m-1"
//                     value={donation.upiId}
//                     onChange={(e) =>
//                       this.props.handleUPIIdChance.call(
//                         this,
//                         e,
//                         donation.donationId
//                       )
//                     }
//                   ></input>
//                   {this.state.isUpdating ? (
//                     <button class="btn btn-primary" type="button" disabled>
//                       <span
//                         class="spinner-grow spinner-grow-sm"
//                         role="status"
//                         aria-hidden="true"
//                       ></span>
//                     </button>
//                   ) : (
//                     <button type="submit" className="btn btn-primary m-1">
//                       Update
//                     </button>
//                   )}
//                 </form>
//               </td>
//             </tr>
//           );
//         })}
//       </>
//     );
//   }
// }
